/*eslint-disable */
import request from "../../../Utils/curl"
import Utility from "../../../Utils/utility"
let evantAnlytics = {
  /**
   * eventSchoolWiseAnalytics
   */
  async eventSchoolWiseAnalytics (context, eventObj = null) {
    Utility.showLoader = true;
    Utility.loadingMsg = "Please wait...."; 
    try {
      let post_data = new FormData();
      if (eventObj) {
        for(let key of Object.keys(eventObj)) {
          if (eventObj[key]) {
            if (typeof eventObj[key] === 'object') {
              post_data.append(key, JSON.stringify(eventObj[key]))
            }
            else {
              post_data.append(key, eventObj[key])
            }
          }
        }
      }

      return await request.curl(context, "event_analytics_school_wise", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err) {
      console.error("Exception occurred at eventSchoolWiseList() and Exception:", err.message)
    }
    finally{
      Utility.showLoader = false;
    }
  },

  /**
   * eventAnalyticsCounts
   */
  async eventAnalyticsCounts (context, eventObj = null) {
    try {
      let post_data = new FormData();
      if (eventObj) {
        for(let key of Object.keys(eventObj)) {
          if (eventObj[key]) {
            post_data.append(key, eventObj[key])
          }
        }
      }

      return await request.curl(context, "event_analytics_count", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err) {
      console.error("Exception occurred at eventSchoolWiseList() and Exception:", err.message)
    }
  },

  /**
   * EventAnalyticsBoothCheckinCount
   */
   async EventAnalyticsBoothCheckinCount (context, eventObj = null) {
    try {
      let post_data = new FormData();
      if (eventObj) {
        for(let key of Object.keys(eventObj)) {
          if (eventObj[key]) {
            post_data.append(key, eventObj[key])
          }
        }
      }

      return await request.curl(context, "event_analytics_booth_checkin_count", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err) {
      console.error("Exception occurred at EventAnalyticsBoothCheckinCount() and Exception:", err.message)
    }
  }
}

export {
  evantAnlytics
}
